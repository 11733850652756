import * as React from 'react';

import {
	Button,
	Card,
	Container,
	Grid,
	GridItem,
	Icon,
} from '@ao-internal/components-react';
import {
	EmailIcon,
	EmailShareButton,
	FacebookIcon,
	FacebookShareButton,
	LinkedinIcon,
	LinkedinShareButton,
	TwitterIcon,
	TwitterShareButton,
} from 'react-share';

import EventbriteButton from 'react-eventbrite-popup-checkout';
import Helmet from 'react-helmet';
import Layout from '../components/Layout';
import { Link } from 'gatsby';
import { OutboundLink } from 'gatsby-plugin-google-gtag';
import { decode } from 'he';
import { graphql } from 'gatsby';
import { sendGtagEvent } from '../helpers/helpers';

export interface EventTemplateProps {
	data: {
		wpEvent: WPEvent;
		wp: {
			generalSettings: {
				title: string;
			};
		};
	};
	pageContext: {
		prev: WPEvent;
		next: WPEvent;
	};
	location: {
		pathname: string;
	};
}

export const eventPageQuery = graphql`
	query($id: String!) {
		wpEvent(id: { eq: $id }) {
			title
			content
			eventFieldGroup {
				date
				location
				city
				ticketsLink
				specialEvent
				eventbriteEventId
				templateImage {
					sourceUrl
				}
			}
		}
		wp {
			generalSettings {
				title
			}
		}
	}
`;

export class EventTemplate extends React.PureComponent<EventTemplateProps, {}> {
	render(): React.ReactNode {
		const event = this.props.data.wpEvent;

		const isSpecialEvent = event.eventFieldGroup.specialEvent;
		const amitaiRegex = /amitai/i;
		const isAmitaiEvent = amitaiRegex.test(event.title);

		const eventDate = new Date(event.eventFieldGroup.date);
		const formattedEventDate = eventDate.toLocaleDateString('en-GB', {
			month: 'short',
			day: 'numeric',
			year: 'numeric',
			hour: 'numeric',
			minute: 'numeric',
		});

		const now = new Date();
		const isPastEvent = eventDate < now;

		let headerImage = require('../images/nwtt01.jpeg');
		if (
			event.eventFieldGroup &&
			event.eventFieldGroup.templateImage &&
			event.eventFieldGroup.templateImage.sourceUrl
		) {
			headerImage = event.eventFieldGroup.templateImage.sourceUrl;
		}

		let pageUrl = this.props.location.pathname;
		if (typeof window !== 'undefined') {
			pageUrl = window.location.href;
		}

		const eventTitle = decode(event.title);

		return (
			<React.Fragment>
				<Helmet
					bodyAttributes={{
						class: isSpecialEvent ? 'special-event' : '',
					}}>
					<title>{eventTitle}</title>
					<link rel="canonical" href={pageUrl} />
				</Helmet>

				<div
					className="blog-page__featured-image"
					style={{
						backgroundImage: `url(${headerImage})`,
					}}>
					<Container className="px-0 flex h-full sub-header">
						<Grid className="items-center">
							<GridItem key="1" className="h-full text-center items-center">
								<div className="video-content">
									{!isSpecialEvent && (
										<h1 className="text-display text-white">{eventTitle}</h1>
									)}
								</div>
							</GridItem>
						</Grid>
					</Container>
				</div>

				<Container className="index__section-one px-0">
					<Grid className="pb-6">
						<GridItem key="1" span={{ def: 12, md: 8 }}>
							<Card className="h-full">
								<h2 className="text-display">{eventTitle}</h2>
								<div
									className="blog-page__tex"
									dangerouslySetInnerHTML={{
										__html: event.content,
									}}
								/>
							</Card>
						</GridItem>
						<GridItem key="2" span={{ def: 12, md: 4 }}>
							<Card className="h-full">
								{event.eventFieldGroup.date && (
									<div className="event-date mb-4">
										<h3 className="text-display-sm">Date &amp; Time</h3>
										<span className="date text-body-sm text-gray-80">
											<Icon
												size="2x"
												icon="calendar"
												className="pr-2 inline-block align-middle"
												label="Date"
											/>
											<span className="inline-block align-middle">
												{formattedEventDate}
											</span>
										</span>
									</div>
								)}
								{event.eventFieldGroup.location && (
									<div className="event-location">
										<h3 className="text-display-sm">Location</h3>
										<span className="location text-body-sm text-gray-80">
											<Icon
												size="2x"
												icon="location"
												className="pr-2 inline-block align-middle"
												label="Location"
											/>
											<span className="inline-block align-middle">
												{event.eventFieldGroup.location}
											</span>
										</span>
									</div>
								)}

								{!isPastEvent && (
									<div className="mt-6">
										{!isSpecialEvent && (
											<OutboundLink
												href={event.eventFieldGroup.ticketsLink}
												target="_blank"
												className="cta">
												Get Tickets
											</OutboundLink>
										)}

										{isSpecialEvent &&
											event.eventFieldGroup.eventbriteEventId &&
											typeof window !== 'undefined' && (
												<EventbriteButton
													className="cta"
													ebEventId={event.eventFieldGroup.eventbriteEventId}
													onClick={() => {
														sendGtagEvent(
															'Clicked tickets button',
															'AOJobsEvent',
															eventTitle,
															0
														);
													}}
													onOrderComplete={(event: any) => {
														sendGtagEvent(
															'Ordered tickets',
															'AOJobsEvent',
															eventTitle,
															0
														);
														
														if (isAmitaiEvent && typeof window !== 'undefined') {
															window.onbeforeunload = () => {};
															
															const orderId = event.orderId ? event.orderId : '';
															setTimeout(() => {
																window.location.href = `/submit-question/?id=${orderId}`;
															}, 800);
														}
													}}>
													Get Tickets
												</EventbriteButton>
											)
										}
									</div>
								)}

								{isPastEvent && (
									<div className="mt-6">This is a past event.</div>
								)}
							</Card>
						</GridItem>
					</Grid>
				</Container>

				<Container className="px-0">
					{(this.props.pageContext.prev || this.props.pageContext.next) && (
						<Grid className="items-center pt-4 blog-post__navigation">
							<GridItem
								key="1"
								span={{ def: 12, md: 4 }}
								className="text-left navigation__prev">
								{' '}
								{this.props.pageContext.prev && (
									<Link
										className="flex items-center justify-start text-link"
										to={'/event/' + this.props.pageContext.prev.slug}>
										<Icon icon="chevron-left" label="Previous event" />
										<span className="ml-4">Previous Event</span>
									</Link>
								)}
							</GridItem>
							<GridItem
								key="2"
								span={{ def: 12, md: 4 }}
								className="text-center blog-post__social">
								<div className="blog-post__social-network">
									<div className="blog-post__social-network__share-button">
										<FacebookShareButton
											url={pageUrl}
											quote={eventTitle}
											beforeOnClick={() => {
												sendGtagEvent('Share Icon', 'Facebook', eventTitle, 0);
												return Promise.resolve();
											}}>
											<FacebookIcon size={32} round />
										</FacebookShareButton>
									</div>
								</div>

								<div className="blog-post__social-network">
									<div className="blog-post__social-network__share-button">
										<TwitterShareButton
											url={pageUrl}
											title={eventTitle}
											beforeOnClick={() => {
												sendGtagEvent('Share Icon', 'Twitter', eventTitle, 0);
												return Promise.resolve();
											}}>
											<TwitterIcon size={32} round />
										</TwitterShareButton>
									</div>
									<div className="blog-post__social-network__share-count">
										&nbsp;
									</div>
								</div>

								<div className="blog-post__social-network">
									<div className="blog-post__social-network__share-button">
										<LinkedinShareButton
											url={pageUrl}
											windowWidth={750}
											windowHeight={600}
											beforeOnClick={() => {
												sendGtagEvent('Share Icon', 'LinkedIn', eventTitle, 0);
												return Promise.resolve();
											}}>
											<LinkedinIcon size={32} round />
										</LinkedinShareButton>
									</div>
								</div>

								<div className="blog-post__social-network">
									<div className="blog-post__social-network__share-button">
										<EmailShareButton
											url={pageUrl}
											subject={eventTitle}
											body={
												'Check out this blog post at tech.ao.com: ' +
												eventTitle +
												' (' +
												pageUrl +
												')'
											}
											beforeOnClick={() => {
												sendGtagEvent('Share Icon', 'Email', eventTitle, 0);
												return Promise.resolve();
											}}>
											<EmailIcon size={32} round />
										</EmailShareButton>
									</div>
								</div>
							</GridItem>
							<GridItem
								key="3"
								span={{ def: 12, md: 4 }}
								className="text-right navigation__next">
								{' '}
								{this.props.pageContext.next && (
									<Link
										className="flex items-center justify-end text-link"
										to={'/event/' + this.props.pageContext.next.slug}>
										<span className="mr-4">Next Event</span>
										<Icon icon="chevron-right" label="Next event" />
									</Link>
								)}
							</GridItem>
						</Grid>
					)}
				</Container>
			</React.Fragment>
		);
	}
}

export default function (props: EventTemplateProps) {
	return (
		<Layout>
			<EventTemplate {...props} />
		</Layout>
	);
}
